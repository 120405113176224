import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./routes/root";
import Event from "./routes/Event";
import Auth from "./routes/auth";
import Confirmation from "./routes/confirmation";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./error-page";
import AuthProvider from "./context/auth";
import NotAuthedRoute from "./routes/NotAuthedRoute";
import Dashboard from "./routes/Dashboard";
import MagicLinkConfirmation from "./routes/MagicLinkConfirmation";
import SignupPage from "./routes/signup";
import SigninPage from "./routes/signin";
import ForgottenPasswordPage from "./routes/forgotten-password";
const root = ReactDOM.createRoot(document.getElementById("root"));

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root />,
//     errorElement: <ErrorPage />,
//     action: createEventAction,
//   },

//   {
//     path: "/auth",
//     element: <Auth />,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "/confirmation",
//     element: <Confirmation />,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "event/:eventId",
//     element: <Event />,
//     errorElement: <ErrorPage />,
//   },
// ]);

root.render(
  // <React.StrictMode>
  <AuthProvider>
    {/* <RouterProvider router={router} /> */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root />} errorElement={<ErrorPage />} />
        <Route element={<NotAuthedRoute />}>
          <Route path="/auth" element={<Auth />} errorElement={<ErrorPage />} />
          <Route
            path="/signup"
            element={<SignupPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/signin"
            element={<SigninPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/forgotten-password"
            element={<ForgottenPasswordPage />}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route
          path="/confirmation"
          element={<Confirmation />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/magiclink"
          element={<MagicLinkConfirmation />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="event/:eventId"
          element={<Event />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard />}
          errorElement={<ErrorPage />}
        />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
