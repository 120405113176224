const IconMoon = () => {
  return (
    <div className="sticky z-0">
      <svg
        className="h-5 w-5 text-grey-600 dark:text-grey-100"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6399 13C21.4955 12.8807 21.3206 12.8043 21.135 12.7796C20.9493 12.7548 20.7605 12.7827 20.5899 12.86C19.5325 13.3439 18.3828 13.593 17.2199 13.59C15.0687 13.5874 13.0059 12.7345 11.481 11.2171C9.9562 9.69979 9.09309 7.64114 9.07989 5.49003C9.08445 4.81586 9.16836 4.14458 9.32989 3.49003C9.36417 3.31557 9.35131 3.13512 9.29265 2.96728C9.23398 2.79944 9.13163 2.65028 8.99613 2.53516C8.86064 2.42004 8.6969 2.34313 8.52179 2.31234C8.34668 2.28156 8.16652 2.29802 7.99989 2.36003C6.43222 3.06494 5.06946 4.15679 4.03965 5.53301C3.00984 6.90924 2.34677 8.52465 2.11274 10.2275C1.87871 11.9304 2.0814 13.6648 2.70176 15.2678C3.32213 16.8708 4.3398 18.2898 5.65919 19.3915C6.97858 20.4932 8.55637 21.2414 10.2443 21.5658C11.9323 21.8903 13.675 21.7803 15.3088 21.2463C16.9426 20.7122 18.4139 19.7716 19.5843 18.5128C20.7547 17.254 21.586 15.7183 21.9999 14.05C22.0503 13.859 22.043 13.6574 21.979 13.4705C21.9149 13.2836 21.7969 13.1199 21.6399 13ZM12.1399 19.69C10.4617 19.6782 8.82809 19.1479 7.46288 18.1718C6.09767 17.1956 5.06756 15.8214 4.51357 14.2372C3.95959 12.6529 3.90883 10.9362 4.36823 9.32203C4.82763 7.70785 5.77475 6.27513 7.07989 5.22003V5.49003C7.08254 8.17851 8.15171 10.7561 10.0528 12.6572C11.9538 14.5582 14.5314 15.6274 17.2199 15.63C17.9258 15.6326 18.6299 15.5589 19.3199 15.41C18.6298 16.7155 17.5964 17.8079 16.331 18.5692C15.0657 19.3305 13.6166 19.7319 12.1399 19.73V19.69Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  );
}

export default IconMoon;