import { API_URL } from "../constants";

export const getEvent = (eventId, success, failure) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: 'include',
  };
  
  return fetch(`${API_URL}/v1/event/${eventId}`, requestOptions)
    .then((response) => response.json())
    .then((event) => {
      success(event);
    }).catch((error) => {
      failure(error);
    });
};

export const createEvent = async (event) => {
  // Override the time of the start_date 
  const {title, description, start_date, end_date, days} = event;
  const dates = []; // The dates that we want to show
  if(start_date === undefined || start_date.length < 1) {
    const start = new Date(); // Default Start and End
    event.start_date = start.toISOString().split('T')[0] + "T00:00:00Z";
  } else {
    event.start_date = event.start_date + "T00:00:00Z"; // Dont add the timezone offset after the Z as server cant parse in this format RFC3339 
  }

  if (end_date === undefined || end_date.length < 1) {
    const end = new Date();
    end.setDate(end.getDate() + 60)
    event.end_date = end.toISOString().split('T')[0] + "T00:00:00Z";;
  } else {
    event.end_date = event.end_date + "T00:00:00Z";
  }
  // Populate all the days inbetween now
  const startDate = new Date(event.start_date);
  const endDate = new Date(event.end_date);
  const currDate = startDate;
  while (currDate < endDate) {
    // Add the day if its in the list
    if (days !== undefined && days.length > 0){ 
      if (days.indexOf(currDate.getDay() + "") > -1) {
        dates.push(currDate.toISOString().split('T')[0] + "T00:00:00Z");
        currDate.setDate(currDate.getDate() + 1);
      } else {
        // Inc the date but dont add
        currDate.setDate(currDate.getDate() + 1);
      }
    } else {
      dates.push(currDate.toISOString().split('T')[0] + "T00:00:00Z");
      currDate.setDate(currDate.getDate() + 1)
    }
  }

  // Add the last day
  if (days !== undefined){ 
    if (days.indexOf(currDate.getDay() + "") > -1) {
      dates.push(endDate.toISOString().split('T')[0] + "T00:00:00Z");
    }
  } else {
    dates.push(currDate.toISOString().split('T')[0] + "T00:00:00Z");
    currDate.setDate(currDate.getDate() + 1)
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({title, description, dates }),
    credentials: 'include',
  };

  return fetch(`${API_URL}/v1/event`, requestOptions)
    .then((response) => response.json())
    .then((event) => {
      return event;
    })
    .catch((error) => {
      console.log('error', error);
    });
}

export const addParticipant =  (data) => {
  const {id, name, hash, callback} = data; // Only send what the server wants

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({date_id: parseInt(id, 10), alias: name}),
      credentials: 'include',
    };
  
    return fetch(`${API_URL}/v1/event/${hash}/participation`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
}

export const removeParticipation = async (data) => {
  const {id, name, hash, callback} = data; // Only send what the server wants
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({date_id: parseInt(id, 10), alias: name}),
    credentials: 'include',
  };

  return fetch(`${API_URL}/v1/event/${hash}/participation`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log("error", error);
    });
}

export const getParticipants = (hash, success, failure) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: 'include',
  };

  return fetch(`${API_URL}/v1/event/${hash}/participants`, requestOptions)
    .then((response) => response.json())
    .then((participants) => {
      success(participants);
    })
    .catch((error) => {
      failure(error);
    });
}

export const getUserEvents = (success, failure) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: 'include',
  }

  return fetch(`${API_URL}/v1/user/events`, requestOptions)
  .then((response) => response.json())
  .then((events) => {
    success(events);
  })
  .catch((error) => {
    failure(error);
  });
}
