import { useEffect, useState } from 'react';
import { useSearchParams, Link } from "react-router-dom";
import Header from "../components/Header";
import { useAuth } from "../context/auth";

const MagicLinkConfirmation = (props) => {

  const [loading, setLoading] = useState(true);
  
  const auth = useAuth();
  const {authError, redirectTo} = auth;
  console.log('authError', authError);
  const [params,] = useSearchParams();
  const id = params.get("id");
  const email = params.get("email");
  console.log('id', id);
  console.log('email', email);

  useEffect(() => {
    const user = auth.validateMagicLink({id, email})
    const {redirect_to} = user;
    if (redirect_to !== "") {
      // TODO REDIRECT
    }
    setLoading(false);
  }, [])


  return (
    <>
      <Header />
      <div class="flex items-center gap-8 justify-center">
        {loading ?(
          <p>Loading....</p>
        ) : ((authError === null) ? (
          <>
            <h1>Magic Link Login Successful</h1>
            <Link to={redirectTo !== "" ? redirectTo : "/"}>Click Here To Continue</Link>
          </>
        ) : (
          <>
            <h1>Something went wrong...</h1>
            <p>{authError}</p>
          </>
        ))}
      </div>
    </>
  );
};

export default MagicLinkConfirmation;