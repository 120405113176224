/**
 * This is a route to ensure that only routes below can be accessed if they are not authed.
 * Eg Signup/login doesnt want to be accessible if someone is logged in.
 */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/auth";

const NotAuthedRoute = () => {
  const auth = useAuth();
  const {user} = auth;
  if (user !== null) return <Navigate to="/" />;
  return <Outlet />;
};

export default NotAuthedRoute;