import Header from "../components/Header";
import Login from "../components/Login";
import MagicLink from "../components/MagicLink";
import Signup from "../components/Signup";

const Auth = (props) => {
  console.log(props);
  return (
    <>
        <Header />
        <div class="flex items-center gap-8 justify-center">
          <Signup showClose={false} showLogo={false} />
          <Login showClose={false}  showLogo={false} />
          <MagicLink showClose={false} showLogo={false} />
        </div>
    </>
  );
};

export default Auth;